html {
  position: fixed; /*caveat, causes: https://discussions.apple.com/thread/251535534?sortBy=rank*/
  top: 0;
  left: 0;
  height: 100%; 
  width: 100%; 
  margin: 0; 
  /* color-scheme: light dark; setting in specific places to avoid inheritance*/
  --primary-background: rgb(242,242,247);
  --secondary-background: black;
  --background-color: light-dark(var(--primary-background), var(--secondary-background));
  --primary-color: black;
  --secondary-color: white;
  --text-color: light-dark(var(--primary-color),var(--secondary-color));
  --primary-input: rgb(0, 122, 255);
  --secondary-input: rgb(235, 184, 0);
  --input-color: light-dark(var(--primary-input),var(--secondary-input));
  --label-color: light-dark(rgb(160, 160, 160),rgb(215, 216, 218));
  --blur-color: light-dark(rgba(255,255,255,0.64), rgba(0,0,0,0.64));
  /* bgColor is set to match meta theme-color in index.html*/
  /* background-color: light-dark(var(--primary-background), var(--secondary-background)); */
}

body {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  overflow: hidden; /*https://www.reddit.com/r/reactjs/comments/1bhmiia/overflow_hidden_doesnt_entirely_prevent_page_on/*/
  /* color-scheme: normal; can be used to prevent most, but not all inheritance (like text color)*/
  touch-action: none; 
  /* https://stackoverflow.com/a/63129727 */
}

/*https://stackoverflow.com/questions/38437613/css-overflow-scrolling-and-hidden-scrollbar-ios*/
::-webkit-scrollbar {
    display: none; 
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); } 
  100% { transform: scale(1); }
} 

/*need following 2 specific selectors to monkey patch ItinerarySegmentDetail*/
svg[class^="Icon__StyledIcon"] {
    background: transparent !important;    
}

div[class^="ItineraryIcon"], div[class^="TileWrapper"] {
    background: transparent;
    /* color: '#fff'; */
}

/*div[class^="ModalFooter"] > */
button[class^="ButtonPrimitive_"] {
  background: rgb(0,154,250)
}

details summary::-webkit-details-marker {
  display: none; /*not working? still clickable/taking up space*/
}

input[list]::-webkit-list-button {
  opacity: 0 !important;
  display: none; /*not working? still clickable/taking up space*/
  transform: scale(0);
}

[inert] input {
  border: 0px;
}

.active .header-container input {
  /*background: rgba(255, 255, 255, 0.1);*/
  border: 0px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2); /*rgb(238,238,239); maybe disable for labels?*/
  border-radius: 0;
}

.standalone .header-container {
  --header-color: var(--primary-input);
} 

select {
  color-scheme: none;
}

/*@media DISABLED { START COMMENT */
.header-container {
  --header-color: var(--text-color);
  input::placeholder,
  input:not([type='checkbox']),
  input:not([type='radio']),
  label {
    min-width: 0px;
    max-height: 36px;
    line-height: 36px;
    font-size: 16pt;
    background: transparent;
    color: var(--header-color);
    opacity: 1;

    text-align: inherit;
    padding: 0;
    padding-right: 0 !important;
    -webkit-appearance: none;

    /*text-align: right; just right placeholder*/
    /*font-family: "SF Display", sans-serif;
    font-weight: 200;*/
  }
}

.styledDate::-webkit-date-and-time-value {
  text-align: right !important;
  color: red !important; /**/
  display: none;
}

/* causing picker on mac safari to never close */
.styledDate::-webkit-datetime-edit-day-field,
.styledDate::-webkit-datetime-edit-hour-field,
.styledDate::-webkit-datetime-edit-ampm-field,
.styledDate::-webkit-datetime-edit-millisecond-field,
.styledDate::-webkit-datetime-edit-minute-field,
.styledDate::-webkit-datetime-edit-month-field,
.styledDate::-webkit-datetime-edit-second-field,
.styledDate::-webkit-datetime-edit-week-field,
.styledDate::-webkit-datetime-edit-year-field,
.styledDate::-webkit-datetime-edit-text {
   color:blue;
   display: none !important;
  -webkit-appearance: none;
}

.styledDate { /*does this work for placeholder?*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.styledDate::before {
  content: attr(data-display-value) !important;
  /*color: white; /* #aaa;*/
}

/* input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
} */

/*input[type='date'], input[type='time'] {
  -webkit-appearance: none;
  //-webkit-max-logical-width: 0%;
}*/

/*
input[type="date"]:focus:before,
input[type="date"]:not([value=""]):before
{
  display: none;
}*/

/*} END COMMENT */